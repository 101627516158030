export default class {
    constructor() {
        //
    }

    isFuture = function (_dateTime) {
        return new Date() < new Date(_dateTime);
    }

    isPast = function (_dateTime) {
        return new Date() > new Date(_dateTime);
    }

    humanDate = function (_date) {
        return window.moment(_date).format('ddd, D MMM YYYY, HH:mm:ss');
    }
}
